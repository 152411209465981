@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

// Heading font

$base-heading-font-family: "neue-kabel";
$base-heading-font-weight: 900 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;
$games-content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;

//bgg page
.total-count {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.games-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
}

.game-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.game-item img {
    width: auto;       /* Ensure the image width adjusts automatically to maintain the aspect ratio */
    height: 150px;     /* Set the height to a fixed value of 200px */
    max-width: 100%;   /* Ensure the image never exceeds the width of its container */
    border-radius: 5px;
}

.footer-col-wrapper ul li {
    display: inline-block;
}

.footer-col-1 {
  width: calc(50% - 30px / 2)
}